<template>
    <div class="top-bar">
        <s-header :name="$t('LAN.feedback_detail')"></s-header>
    </div>

    <div class="service" >
        <div class="service-detail" v-if="contentObj">
            <div class="detail">
                <div class="top">
                    <em> {{ $t(menus[contentObj.type].label) }} </em>
                    <span  :style="{ color: statusStr[contentObj.status].color }"
                    >
                        {{ statusStr[contentObj.status].label }}
                    </span>
                </div>

                <div
                    class="item"
                    v-for="(item, key) in menus[contentObj.type].inputs"
                    :key="key"
                >
                    <div class="type">{{ $t(item.label) }}</div>
                    <div class="aside">
                        <span>
                            {{
                                item.contentJson
                                    ? contentObj.content[item.name]
                                    : contentObj[item.name]
                            }}</span
                        >
                    </div>
                </div>

                <div class="item">
                    <div class="type">{{ $t("LAN.contact_email") }}</div>
                    <div class="aside">
                        <span>{{ contentObj.email }}</span>
                    </div>
                </div>

                <div class="item" v-if="photoList.length">
                    <div class="type">{{ $t("LAN.screenshot") }}</div>
                    <van-uploader
                        class="smalluploader"
                        v-model="photoList"
                        :deletable="false"
                        :show-upload="false"
                    />
                </div>
                <div class="item">
                    <div class="type">{{ $t("LAN.detailed_info") }}</div>
                    <div class="aside">
                        <span
                            v-html="
                                contentObj.content
                                    ? contentObj.content.content
                                    : ''
                            "
                        ></span>
                    </div>
                </div>
            </div>

            <div class="reply">
                <div
                    class="item desc"
                    v-for="(data, key) in replyList"
                    :key="key"
                >
                    <div :class="data.isMy ? 'type2' : 'type'">
                        {{
                            data.isMy
                                ? $t("LAN.my_reply")
                                : formate($t("LAN.kf_reply"), data.kfName)
                        }}
                    </div>
                    <div class="aside">
                        <p
                            :class="data.isMy ? 'atype2' : 'atype'"
                            v-html="data.content"
                        ></p>
                        <van-uploader
                            class="smalluploader"
                            :size="30"
                            :class="data.isMy ? 'atype2' : 'atype'"
                            v-if="data.photoList.length"
                            v-model="data.photoList"
                            :deletable="false"
                            :show-upload="false"
                        />
                    </div>
                </div>

                <!-- 回复框 -->
                <div class="service-textarea" v-if="showService">
                    <div class="tips">{{ $t("LAN.feedback_tips") }}</div>
                    <div class="service-item-textarea">
                        <textarea
                            :placeholder="$t('LAN.input_feedback')"
                            v-model="feedContent"
                            maxlength="250"
                            class="service-textarea"
                        />
                    </div>
                    <div class="uploader">
                        <van-uploader
                            v-model="uploadList"
                            multiple
                            :max-count="3"
                            :after-read="afterRead"
                        />
                    </div>
                    <!-- 提交按钮 -->
                    <div style="margin: 16px">
                        <van-button
                            round
                            block
                            color="#6e87ea"
                            native-type="submit"
                            @click="onSubmit"
                            >{{ $t("LAN.submit_feedback") }}</van-button
                        >
                    </div>
                </div>

                <!-- 评分框 -->
                <div class="score" v-if="showScore">
                    <h4 class="title1">
                        {{ $t("LAN.service_score") }}>
                    </h4>
                    <div class="info">
                        <span>{{ formate($t("LAN.score"), rateVal) }}</span>
                    </div>
                    <div class="rate">
                        <van-rate  v-model="rateVal"   allow-half       void-icon="star"    color="#ffd21e"   void-color="#eee"    :size="30" />
                    </div>
                    <p class="graded" v-if="scored">{{ $t("LAN.scored") }}</p>
                    <a
                        class="btn-submit"
                        style="background-color: #f5c918"
                        @click="onSubmitRate"
                        >{{ $t("LAN.submit") }}</a
                    >

                    <!-- 打赏框 -->
                    <div class="reward" v-if="showReward">
                        <h4 >
                            {{ $t("LAN.feedback_reward_1") }}>
                        </h4>
                        <div class="flex-row justify-around wrap">
                            <div
                                class="item flex-col items-center"
                                v-for="(pay, key) in rewardList"
                                :key="key"
                            >
                                <img
                                    :src="
                                        require(`@/assets/feedback/` + pay.icon)
                                    "  
                                />
                                <span>{{ getAmount(pay.amount) }}</span>
                                <a class="btn" @click="onPay(pay)">{{
                                    $t("LAN.feedback_reward_2")
                                }}</a>
                            </div>
                        </div>
                        <span>{{ $t("LAN.feedback_reward_3") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import sHeader from "@/components/Header";
import { requestDetail, requestReply, requestRate } from "@/service/feedback";
import { validatenJson, validateNull } from "@/utils/validate";
import uploadFile from "@/utils/uploadFile";
import { feedbackMenus } from "@/configs/feedback";
import { formate } from "@/utils/string";
import { openUrl } from "@/utils/common";
import { POINTS_PAY_DATA } from "@/configs/global";
import { openWebView } from "@/utils/common";
import { requestPayMode } from "@/service/pay";
import { requestExchangeRate } from "@/service/exchange";

export default {
    setup() {
        const route = useRoute();
        const { t } = useI18n();
        const store = useStore();

        const state = reactive({
            detailId: 0,
            photoList: [],
            contentObj: null,
            replyList: [],
            statusStr: {
                0: {
                    label: t("LAN.processing"), // 处理中
                    color: "#ff5353",
                },
                1: {
                    label: t("LAN.replied"), // 已回复
                    color: "#5ac845",
                },
                2: {
                    label: t("LAN.completed"), // 已完成
                    color: "#6e87ea",
                },
            },
            menus: feedbackMenus,
            rateVal: 0,
            showScore: false,
            showReward: false,
            scored: false,
            showService: false,
            feedContent: "",
            uploadList: [],
            lastReplyKfName: "88",

            rewardList: [
                {
                    amount: 6,
                    icon: "good_4.png",
                    productId: "com.feedback.reward.iap.6",
                },
                {
                    amount: 15,
                    icon: "good_6.png",
                    productId: "com.feedback.reward.iap.15",
                },
                {
                    amount: 30,
                    icon: "good_2.png",
                    productId: "com.feedback.reward.iap.30",
                },
                {
                    amount: 50,
                    icon: "good_61.png",
                    productId: "com.feedback.reward.iap.50",
                },
            ],

            currencyValue: "CNY",
            currencyRate: 1,
        });

        onMounted(async () => {
            initFunc();

            const { id } = route.params;
            state.detailId = id;
            Toast.loading(t("LAN.loading"));

            const result = await requestDetail(state.detailId);
            state.contentObj = result.obj;

            // 拆分内容
            state.contentObj.content = result.obj.content;
            if (validatenJson(result.obj.content)) {
                state.contentObj.content = JSON.parse(result.obj.content);
            }
            state.rateVal = result.obj.rate / 2;
            state.scored = state.rateVal > 0;

            state.showReward = store.getters.isOtherPayMode;

            // 截图
            if (result.obj.photo != "") {
                let photos = JSON.parse(result.obj.photo);
                if (photos.length > 0) {
                    for (let i = 0; i < photos.length; i++) {
                        let url = photos[i].replace("http://", "https://");
                        state.photoList.push({ url: url });
                    }
                }
            }

            state.replyList = result.replyList;
            state.showScore = state.replyList.length > 0 || state.contentObj.status > 0;

            let kfNames = {
                "xiechuqiao_10":"Ms Xie",
                "jiangzhigui_7":"Xiao Ming",
                "jiangxuankang_9":"Xiao tian",
                "liuzhipeng_8":"Jack",
            }
            
            for (const data of state.replyList) {
                data.photoList = [];
                if (data.photo != "") {
                    let photos = JSON.parse(data.photo);
                    if (photos.length > 0) {
                        for (let i = 0; i < photos.length; i++) {
                            let url = photos[i].replace("http://", "https://");
                            data.photoList.push({ url: url });
                        }
                    }
                }
                data.isMy = data.username == "0";
                data.kfid = 0;
                data.kfName = "";
                if (!data.isMy) {
                    let sp = data.username.split("_");
                    data.kfid = sp[1] ? sp[1] : 88;
                    data.kfName = kfNames[data.username] ? kfNames[data.username] : "88"
                    state.lastReplyKfName = data.kfName;
                }
            }
            checkShowService();

            if (result.obj.status == 0) {
                state.replyList.push({
                    photoList: [],
                    isMy: false,
                    username: "",
                    kfid: 88,
                    kfName: state.lastReplyKfName,
                    content: t("LAN.feedback_processing"),
                });
            }
            Toast.clear();

            state.currencyValue = store.getters.currency;
            const exchangeResult = await requestExchangeRate(
                state.currencyValue
            );
            state.currencyRate = exchangeResult.rate;
        });

        const getAmount = (cnyAmount) => {
            return (
                state.currencyValue +
                " " +
                (cnyAmount * state.currencyRate).toFixed(2)
            );
        };

        const checkShowService = () => {
            let myCount = 0;
            for (const data of state.replyList) {
                if (data.content == t("LAN.feedback_processing")) {
                    continue;
                }
                if (!data.isMy) {
                    myCount = 0;
                } else {
                    myCount++;
                }
            }
            state.showService = state.contentObj.status != 2 && myCount < 3;
        };

        const initFunc = () => {
            window.openTokenUrl = function (url) {
                console.log(url)
                if (url.indexOf("?") > -1) {
                    url = url + "&token=" + encodeURI(store.getters.token);
                } else {
                    url = url + "?token=" + encodeURI(store.getters.token);
                }
                openUrl(url);
            };
            window.openUrl = function (url) {
                openUrl(url);
            };
        };

        const afterRead = (file) => {
            let upload = function (file) {
                file.status = "uploading";
                file.message = t("LAN.uploading");
                uploadFile(file.file, function (url) {
                    if (url == "") {
                        file.status = "failed";
                        file.message = t("LAN.upload_failed");
                    } else {
                        file.status = "success";
                        file.message = "";
                        file.url = url;
                    }
                });
            };
            if (file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    upload(file[i]);
                }
            } else {
                upload(file);
            }
        };

        const onSubmit = async () => {
            let photos = [];
            for (let i = 0; i < state.uploadList.length; i++) {
                let file = state.uploadList[i];
                if (file.status == "uploading") {
                    Toast(t("LAN.upload_tips"));
                    return;
                }
                photos.push(file.url);
            }

            if (validateNull(state.feedContent)) {
                Toast(t("LAN.input_feedback"));
                return false;
            }

            Toast.loading(t("LAN.sending"));
            await requestReply({
                feedbackId: state.detailId,
                content: state.feedContent,
                photo: photos.length > 0 ? JSON.stringify(photos) : "",
            });
            Toast.success(t("LAN.send_success"));

            state.replyList.push({
                photoList: state.uploadList,
                isMy: true,
                username: "",
                content: state.feedContent,
            });

            checkShowService();

            state.uploadList = [];
            state.feedContent = "";
            state.contentObj.status = 0;
            state.replyList.push({
                photoList: [],
                isMy: false,
                username: "",
                kfid: 88,
                kfName: state.lastReplyKfName,
                content: t("LAN.feedback_processing"),
            });
        };

        const onSubmitRate = async () => {
            if (state.rateVal <= 0) {
                Toast(t("LAN.score_tips"));
                return;
            }
            if (state.rateVal * 2 == state.contentObj.rate) {
                Toast.success(t("LAN.thank_feedback"));
                return;
            }
            Toast.loading(t("LAN.sending"));
            await requestRate({
                feedbackId: state.detailId,
                rate: state.rateVal * 2,
            });
            Toast.success(t("LAN.thank_feedback"));
            state.scored = true;
        };

        const onPay = async (payData) => {
            let params = {
                //游戏id固定1040
                gameId: POINTS_PAY_DATA.gameId,
                channel: 1,
                lang: store.getters.lang,
                token: encodeURI(store.getters.token),
                userId: store.getters.userId,
                serId: store.getters.roleServerId,
                serName: encodeURI(store.getters.roleServerName),
                roleId: store.getters.roleId,
                rolename: encodeURI(store.getters.roleName),
                gameName: encodeURI(store.getters.gameName),
                vip: encodeURI(store.getters.roleVip),
                roleLevel: store.getters.roleLevel,
                currency: store.getters.currency,
                userName: encodeURI(store.getters.username),
                password: store.getters.password,

                amount: payData.amount,
                outOrderNo: encodeURI("feedback_" + state.detailId),
                productId: encodeURI(payData.productId),
                points: store.getters.points,
            };
            Toast.loading(t("LAN.pay_paying"));
            const result = await requestPayMode({
                gameId: POINTS_PAY_DATA.gameId,
                level: store.getters.roleLevel,
                channel: 1,
            });
            Toast.clear();

            params["mode"] = encodeURI(result.mode);
            params["discount"] = encodeURI(result.discount);
            openWebView(
                document.location.origin + "/sdkweb/payCenter/pay",
                params,
                ""
            );
        };

        return {
            ...toRefs(state),
            afterRead,
            onSubmit,
            formate,
            onSubmitRate,
            getAmount,
            onPay,
        };
    },
    components: {
        sHeader,
    },
};
</script>

<style lang="less" scoped>
/deep/ .smalluploader .van-uploader__preview-image {
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 5px;
    margin-top: 5px;
}

.service {
    min-height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    text-align: left;

    .service-detail {
        padding: 0 10px;
        background-color: #fff;
        .detail {
            border-bottom: 1px solid #eee;
            margin-bottom: 15px;
            .top {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                border-bottom: 1px solid #eee;
                margin-bottom: 10px;

                em {
                    font-style: normal;
                    font-size: 15px;
                    color: #333;
                }
                span {
                    flex: 1;
                    font-size: 15px;
                    color: #ff5353;
                    text-align: right;
                }
                //阿拉伯样式
                .L {
                    text-align: left;
                }
            }
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                .type {
                    font-size: 15px;
                }
                .aside {
                    width: 230px;
                    font-size: 15px;
                    color: #b1b1b1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    span {
                        text-align: right;
                    }
                }
            }
        }

        .reply {
            .item {
                margin-bottom: 20px;
                .type {
                    font-size: 15px;
                    color: #33bfff;
                }
                .type2 {
                    display: flex;
                    flex: 1;
                    font-size: 15px;
                    color: #5ac845;
                    justify-content: flex-end;
                }
                .aside {
                    font-size: 15px;
                    color: #b1b1b1;
                    align-items: center;
                    margin-top: 5px;
                    padding: 5px;
                    border-radius: 7px;
                    background-color: #eee;

                    .atype2 {
                        display: flex;
                        flex: 1;
                        justify-content: flex-end;
                    }
                }

                /deep/ a {
                    color: green;
                    text-decoration: underline;
                }
            }

            .service-textarea {
                .tips {
                    height: 60px;
                    font-size: 13px;
                    color: #b1b1b1;
                    display: flex;
                    align-items: center;
                    margin-top: -10px;
                }
                .service-item-textarea {
                    height: 100px;
                    padding: 16px;
                    border-radius: 10px;
                    border: 1px solid #eee;
                    .service-textarea {
                        width: 100%;
                        height: 100px;
                        padding: 0;
                        border: 0;
                        font-size: 15px;
                        color: #666;
                        outline: none;
                        -webkit-appearance: none;
                        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    }
                }
                .uploader {
                    margin-top: 20px;
                }
            }

            .score {
                padding: 0px 0 20px 0;
                margin-top: 24px;
                margin-bottom: 24px;
                border-radius: 10px;
                border: 1px solid #eee;
                .info {
                    font-size: 15px;
                    color: #333;
                    text-align: center;
                    span {
                        font-size: 20px;
                        color: #fdc942;
                    }
                }
                .graded {
                    font-size: 15px;
                    text-align: center;
                    color: #fdc942;
                }
                .rate {
                    text-align: center;
                }
                .btn-submit {
                    width: 120px;
                    height: 40px;
                    margin: 10px auto;
                    background-color: #6e87ea;
                    border-radius: 10px;
                    font-size: 15px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                h4 {
                    text-align: left;
                    font-size: 15px;
                }
                .title1 {
                    padding: 0px 10px;
                    margin-top: 10px;
                }

                .reward {
                    padding: 0px 10px;
                    font-size: 15px;
                    text-align: center;
                    .item {
                        padding: 10px 10px;
                        background-color: #f1f8fe;
                        border-radius: 10px;
                        span {
                            width: 70px;
                            text-align: center;
                            margin-top: 5px;
                            font-size: 12px;
                            color: #6e87ea;
                        }
                        img {
                            width: 40px;
                            height: 40px;
                        }
                        .btn {
                            min-width: 60px;
                            padding: 5px 5px;
                            background-color: #6e87ea;
                            border-radius: 5px;
                            font-size: 15px;
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 10px;
                        }
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
</style>